// import { authHeader } from '../helpers';

import environment from 'environment';
import axios from 'axios';
import { configOptions } from 'final-form';
export const userService = {
  login,
  logout,
  register,
  activation,
  resendEmail,
  passwordForgot,
  passwordReset
};

function login(username, password) {
  return axios
    .post(environment.api.subscriberUrl + '/authenticate', {
      username,
      password,
    })
    .then((response) => {
      localStorage.setItem('user', JSON.stringify(response.data));
      return response.data;
    })
    .catch((error) => Promise.reject({ message: error.response.data, status: error.response.status }));
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
}

async function register(username, password) {
  return axios
    .post(environment.api.subscriberUrl + '/register', {
      username,
      password,
    })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response.data));
}

function activation(guid) {
  return axios
    .get(environment.api.subscriberUrl + `/activate`, {
      params: {
        activationCode: guid,
      },
    })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response.data));
}

function resendEmail(username) {
  return axios
    .post(environment.api.subscriberUrl + `/resend`, {
      username,
    })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response.data));
}

function passwordForgot(username) {
  return axios
    .post(environment.api.subscriberUrl + `/password/forgot`, {
      username,
    })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response.data));
}

function passwordReset(password, resetPasswordCode) {
  return axios
    .post(environment.api.subscriberUrl + `/password/reset`, {
      password,
      resetPasswordCode
    })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error.response.data));
}
