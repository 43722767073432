import SignIn from 'views/SignIn';
// import SignUp from 'views/SignUp';
// import ForgotPassword from 'views/ForgotPassword';
// import ResetPassword from 'views/ResetPassword';
// import Home from 'views/Home';
// import Pricing from 'views/Pricing';
// import Activation from 'components/Activation';
// import Documentation from 'views/Admin/Documentation/Documentation';
// import Error from 'views/Error';

const baseRoute = '/';

const routes = [
  { url: '/login', component: SignIn },
  // { url: '/pricing', component: Pricing },
  // { url: '/documentation', component: Documentation },
  // { url: '/sign-in', component: SignIn },
  // { url: '/sign-up', component: SignUp },
  // { url: '/activate/:guid', component: Activation },
  // { url: '/forgot-password/:resetEmail', component: ForgotPassword },
  // { url: '/resetpassword/:resetPasswordCode', component: ResetPassword },
  // { url: '*', component: Error }, //* al final para que se envíe solo en no-route-matching-case
];

export { baseRoute, routes };
