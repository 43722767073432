import withRoot from 'modules/withRoot';
// --- Post bootstrap -----
import React from 'react';
import AppFooter from 'components/AppFooter';
import AppAppBar from 'components/AppAppBar';
import { Redirect, Switch, Route } from 'react-router-dom';
import { routes } from 'routes/home';

const Body = () => (
  <Switch>
    {routes.map((r) => (
      <Route
        exact={r.url !== '*'} //* la unica que no es exact es * porque es cualquier ruta => Error Page
        key={r.url}
        path={r.url}
        component={r.component}
      />
    ))}
    <Redirect from="/" to="/home" />
  </Switch>
);

function Index() {
  return (
    <React.Fragment>
      <AppAppBar />
      <Body />
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(Index);
