/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from '@material-ui/icons/Dashboard';
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import Unarchive from "@material-ui/icons/Unarchive";
// import Language from "@material-ui/icons/Language";
// core components/views for Admin layout
import DashboardPage from 'views/Admin/Dashboard/Dashboard';
import Documentation from 'views/Admin/Documentation/Documentation';
import UpgradeToPro from 'views/Admin/UpgradeToPro/UpgradeToPro';

const dashboardRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: Dashboard,
    component: DashboardPage,
    layout: '/admin',
  },
  {
    path: '/documentation',
    name: 'Documentation',
    icon: LibraryBooks,
    component: Documentation,
    layout: '/admin',
  },
  {
    path: '/plan',
    name: 'Plan',
    icon: Unarchive,
    component: UpgradeToPro,
    layout: '/admin',
  },
];

export default dashboardRoutes;
