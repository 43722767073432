import withRoot from 'modules/withRoot';
// --- Post bootstrap -----
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { Field, Form, FormSpy } from 'react-final-form';
import Typography from 'components/Typography';
import AppAppBar from 'components/AppAppBar';
import AppForm from 'components/AppForm';
import { email, required } from 'modules/form/validation';
import RFTextField from 'modules/form/RFTextField';
import FormButton from 'modules/form/FormButton';
import FormFeedback from 'modules/form/FormFeedback';
import { connect } from 'react-redux';
import { userActions } from 'redux/actions';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(6),
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  feedback: {
    marginTop: theme.spacing(2),
  },
}));

function SignUp(props) {
  const classes = useStyles();
  const [sent, setSent] = React.useState(false);
  const { registeringIn } = props;
  const history = useHistory();

  const validate = (values) => {
    const errors = required(['email', 'password'], values);

    if (!errors.email) {
      const emailError = email(values.email);
      if (emailError) {
        errors.email = email(values.email);
      }
    }

    return errors;
  };

  const handleSubmit = (values) => {
    setSent(true);
    const { email, password } = values;
    const { dispatch } = props;
    if (email && password) {
      dispatch(userActions.register(email, password, history));
    }
  };

  return (
    <React.Fragment>
      <AppAppBar />
      <AppForm>
        <React.Fragment>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Sign Up
          </Typography>
          <Typography variant="body2" align="center">
            <Link href="/sign-in/" underline="always">
              Already have an account?
            </Link>
          </Typography>
        </React.Fragment>
        <Form onSubmit={handleSubmit} subscription={{ submitting: true }} validate={validate}>
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} className={classes.form} noValidate>
              <Grid container spacing={2}>
                {/* <Grid item xs={12} sm={6}>
                  <Field
                    autoFocus
                    component={RFTextField}
                    autoComplete="fname"
                    fullWidth
                    label="First name"
                    name="firstName"
                    required
                  />
                </Grid> */}
                {/* <Grid item xs={12} sm={6}>
                  <Field
                    component={RFTextField}
                    autoComplete="lname"
                    fullWidth
                    label="Last name"
                    name="lastName"
                    required
                  />
                </Grid> */}
              </Grid>
              <Field
                autoComplete="email"
                component={RFTextField}
                disabled={(submitting || sent) && registeringIn}
                fullWidth
                label="Email"
                margin="normal"
                name="email"
                required
              />
              <Field
                fullWidth
                component={RFTextField}
                disabled={(submitting || sent) && registeringIn}
                required
                name="password"
                autoComplete="current-password"
                label="Password"
                type="password"
                margin="normal"
              />
              <FormSpy subscription={{ submitError: true }}>
                {({ submitError }) =>
                  submitError ? (
                    <FormFeedback className={classes.feedback} error>
                      {submitError}
                    </FormFeedback>
                  ) : null
                }
              </FormSpy>
              <FormButton
                className={classes.button}
                disabled={(submitting || sent) && registeringIn}
                color="secondary"
                fullWidth
              >
                {(submitting || sent) && registeringIn ? 'In progress…' : 'Sign Up'}
              </FormButton>
            </form>
          )}
        </Form>
      </AppForm>
    </React.Fragment>
  );
}
function mapStateToProps(state) {
  const { registeringIn } = state.authentication;
  return {
    registeringIn,
  };
}
export default withRoot(connect(mapStateToProps)(SignUp));
