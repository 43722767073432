import React from 'react';
// react plugin for creating charts
import ChartistGraph from 'react-chartist';
// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
import AccessTime from '@material-ui/icons/AccessTime';
import LockOpen from '@material-ui/icons/LockOpen';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import CalendarToday from '@material-ui/icons/CalendarToday';
import { connect } from 'react-redux';

// core components
import GridItem from 'components/Admin/Grid/GridItem';
import GridContainer from 'components/Admin/Grid/GridContainer';
import Card from 'components/Admin/Card/Card';
import CardHeader from 'components/Admin/Card/CardHeader';
import CardBody from 'components/Admin/Card/CardBody';
import CardFooter from 'components/Admin/Card/CardFooter';
import CardIcon from 'components/Admin/Card/CardIcon';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle';
import CircularProgress from '@material-ui/core/CircularProgress';

import { requestService } from 'services';
import { buildChart } from 'helpers';
import { useEffect, useState } from 'react';

const useStyles = makeStyles(styles);

const Dashboard = ({ user }) => {
  const [dailyRequests, setDailyRequests] = useState({});
  const [monthlyRequests, setMonthlyRequests] = useState({});
  const [hourlyRequests, setHourlyRequests] = useState({});
  const [remainingRequests, setRemainingRequests] = useState({ loading: true });

  const [updatedAtDaily, setUpdatedAtDaily] = useState(null);
  const [updatedAtMonthly, setUpdatedAtMonthly] = useState(null);
  const [updatedAtHourly, setUpdatedAtHourly] = useState(null);

  useEffect(() => {
    (async () => {
      const remaining = await requestService.getRequests(user.apiKey);
      setRemainingRequests({ ...remaining, loading: false, error: !remaining });
    })();
  }, [user.token, user.apiKey]);

  useEffect(() => {
    (async () => {
      const dRequests = await requestService.getLastWeekRequests(user.apiKey);
      if (!dRequests) {
        setDailyRequests({ error: true });
      } else {
        const chartWeek = buildChart(dRequests.labels, dRequests.values, new Date().getDay());
        setDailyRequests({ ...chartWeek, error: false });
        const last = new Date(dRequests.lastRequest);
        setUpdatedAtDaily(last.toLocaleDateString());
      }
    })();
  }, [user.token, user.apiKey]);

  useEffect(() => {
    (async () => {
      const mRequests = await requestService.getLastYearRequests(user.apiKey);
      if (!mRequests) {
        setMonthlyRequests({ error: true });
      } else {
        const chartYear = buildChart(mRequests.labels, mRequests.values, new Date().getMonth());
        setMonthlyRequests({ ...chartYear, error: false });
        const last = new Date(mRequests.lastRequest);
        setUpdatedAtMonthly(last.toLocaleDateString());
      }
    })();
  }, [user.token, user.apiKey]);

  useEffect(() => {
    (async () => {
      const hRequests = await requestService.getHourlyRequests(user.apiKey);
      if (!hRequests) {
        setHourlyRequests({ error: true });
      } else {
        const chartHours = buildChart(hRequests.labels, hRequests.values);
        setHourlyRequests({ ...chartHours, error: false });
        const last = new Date(hRequests.lastRequest);
        setUpdatedAtHourly(last.toLocaleDateString());
      }
    })();
  }, [user.token, user.apiKey]);

  const classes = useStyles();
  return (
    <div>
      <GridContainer justify='center'>
        <GridItem xs={12} sm={12} md={12} lg={8} xl={6}>
          <Card>
            <CardHeader color="info" icon stats>
              <CardIcon color="info">
                <LockOpen />
              </CardIcon>
              <p className={classes.cardCategory}>Plan: {user.plan.toUpperCase()} | ApiKey</p>
              <h3 className={classes.cardTitle}>
                {user.apiKey}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <CalendarToday /> Today Available Requests

              </div>

              <div className={classes.stats}>
                <h3 className={classes.cardTitle}>
                  {remainingRequests.error ?
                    <h4>Error!</h4> :
                    (remainingRequests.loading ?
                      <CircularProgress /> :
                      (remainingRequests.requestsPerDay < 0 ?
                        `Unlimited` :
                        `${remainingRequests.remainingRequests} / ${remainingRequests.requestsPerDay}`))}
                </h3>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="warning">
              {dailyRequests.error ? (
                <h2> Error! </h2>
              ) : dailyRequests.data ? (
                <ChartistGraph
                  className="ct-chart"
                  data={dailyRequests.data || {}}
                  type="Line"
                  options={dailyRequests.options || {}}
                  listener={dailyRequests.animation || []}
                />
              ) : (
                    <CircularProgress />
                  )}
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Daily Requests</h4>
              <p className={classes.cardCategory}>
                <span>Requests from the last 7 days</span>
              </p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> updated on {updatedAtDaily}
              </div>
            </CardFooter>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="warning">
              {hourlyRequests.error ? (
                <h2> Error! </h2>
              ) : hourlyRequests.data ? (
                <ChartistGraph
                  className="ct-chart"
                  data={hourlyRequests.data || {}}
                  type="Bar"
                  options={hourlyRequests.options || {}}
                  responsiveOptions={hourlyRequests.responsiveOptions || []}
                  listener={hourlyRequests.animation || {}}
                />
              ) : (
                    <CircularProgress />
                  )}
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Hourly Requests</h4>
              <p className={classes.cardCategory}>Average requests from last 30 days</p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> updated on {updatedAtHourly}
              </div>
            </CardFooter>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="warning">
              {monthlyRequests.error ? (
                <h2> Error! </h2>
              ) : monthlyRequests.data ? (
                <ChartistGraph
                  className="ct-chart"
                  data={monthlyRequests.data || {}}
                  type="Line"
                  options={monthlyRequests.options || {}}
                  listener={monthlyRequests.animation || []}
                />
              ) : (
                    <CircularProgress />
                  )}
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Monthly Requests</h4>
              <p className={classes.cardCategory}>Requests from the last 12 months</p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> updated on {updatedAtMonthly}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

export default connect(mapStateToProps)(Dashboard);
