import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
import Close from '@material-ui/icons/Close';
import Check from '@material-ui/icons/Check';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

// core components
import GridItem from 'components/Admin/Grid/GridItem';
import GridContainer from 'components/Admin/Grid/GridContainer';
import Danger from 'components/Admin/Typography/Danger';
import Warning from 'components/Admin/Typography/Warning';
import Success from 'components/Admin/Typography/Success';
import Button from 'components/Admin/CustomButtons/Button';
import Card from 'components/Admin/Card/Card';
import CardHeader from 'components/Admin/Card/CardHeader';
import CardBody from 'components/Admin/Card/CardBody';
import { requestService } from 'services';
import CircularProgress from '@material-ui/core/CircularProgress';
import { capitalize } from '../../../helpers/string';

import CheckoutForm from './CheckoutForm';

import { planConstants } from 'constants/plan.constants';
import { subscriptionService } from 'services/subscription.service';

import { userActions, alertActions } from 'redux/actions';
import { useDispatch } from 'react-redux';
import AlertDialogSlide from 'components/AlertDialogSlide';
import { SimpleDialog } from 'components/SimpleDialog';

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  tableUpgradeWrapper: {
    display: 'block',
    width: '100%',
    overflowX: 'auto',
    WebkitOverflowScrolling: 'touch',
    MsOverflowStyle: '-ms-autohiding-scrollbar',
  },
  table: {
    width: '100%',
    maxWidth: '100%',
    marginBottom: '1rem',
    backgroundColor: 'transparent',
    borderCollapse: 'collapse',
    display: 'table',
    borderSpacing: '2px',
    borderColor: 'grey',
    '& thdead tr th': {
      fontSize: '1.063rem',
      padding: '12px 8px',
      verticalAlign: 'middle',
      fontWeight: '300',
      borderTopWidth: '0',
      borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
      textAlign: 'inherit',
    },
    '& tbody tr td': {
      padding: '12px 8px',
      verticalAlign: 'middle',
      borderTop: '1px solid rgba(0, 0, 0, 0.06)',
    },
    '& td, & th': {
      display: 'table-cell',
    },
  },
  center: {
    textAlign: 'center',
  },
};

const useStyles = makeStyles(styles);

const UpgradeToPro = ({ user }) => {
  const classes = useStyles();
  const [plansInfo, setPlansInfo] = useState({ loading: true, error: false });
  const [open, setOpen] = useState(false)
  const [openUnsubscribe, setOpenUnsubscribe] = useState(false)
  const [plan, setPlan] = useState('Tourist')
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      setPlansInfo({ loading: true, error: false });

      const plans = await requestService.getPlans();
      requestService.getPlanInfo(user.username).then((data) => {
        dispatch(userActions.setPlan(data.plan));
      });

      console.log(plans);
      setPlansInfo({ ...plans, loading: false, error: !plans });
    })()
  }, [])

  function handleOpen(selectedPlan) {
    if (selectedPlan === planConstants.Tourist) {
      setOpenUnsubscribe(true);
    } else {
      setOpen(true);
      setPlan(selectedPlan);
    }
  }

  function handleClose() {
    setOpen(false);
    setPlan('Tourist');
  }

  function handleSubmit(answer) {
    if (answer) {
      setLoading(true);
      subscriptionService.cancelSubscription(user.username).then(() => {
        dispatch(userActions.setPlan(plan));
        dispatch(
          alertActions.success(
            'Successfully unsubscribed.',
          ),
        );
      }).then(() => setLoading(false)).catch(() => setLoading(false));
    }
    setOpenUnsubscribe(false);
  }

  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={12} md={8}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>Wo Locations Plans</h4>
          </CardHeader>
          {plansInfo.error ? <h3 className={classes.center}> Something went wrong, please try again! </h3> : (plansInfo.loading ? <CircularProgress /> :
            <CardBody>
              <div className={classes.tableUpgradeWrapper}>
                <table className={classes.table}>
                  <thead>
                    <tr>
                      <th />
                      {/* {plansInfo.plans.map(plan => (
                        <th className={classes.center}><h5><strong>{capitalize(plan.name)}</strong></h5></th>))} */}
                      {Object.keys(plansInfo.plans).map(planName => (
                        <th key={planName} className={classes.center}>{capitalize(plansInfo.plans[planName].name)}</th>))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Requests per day</td>
                      {plansInfo.plans.map(plan => (
                        <td key={plan.requestPerDay} className={classes.center}>{plan.requestPerDay < 0 ? 'Unlimited' : plan.requestPerDay}</td>))}
                    </tr>
                    <tr>
                      <td>Request frequency</td>
                      {plansInfo.plans.map(plan => (
                        <td key={plan.requestPerSecond} className={classes.center}>{plan.requestPerSecond < 0 ? 'Unlimited' : `${plan.requestPerSecond}/s`}</td>))}
                    </tr>
                  </tbody>

                  <thead>
                    <tr>
                      <td><h5>Api Services</h5></td>
                      <th />  <th /><th />
                    </tr>
                  </thead>

                  <tbody>

                    {
                      plansInfo.services.api.map(service => (
                        <tr>
                          <td>{service}</td>
                          {plansInfo.plans.map(plan => (
                            <td className={classes.center}>{plan.forbiddenRequests.api.includes(service) ?
                              <Danger><Close /></Danger> : <Success><Check /></Success>}</td>))}
                        </tr>
                      ))
                    }

                  </tbody>

                  <thead>
                    <tr>
                      <td><h5>Component Services</h5></td>
                      <th /><th /><th />
                    </tr>
                  </thead>

                  <tbody>

                    {
                      plansInfo.services.component.map(service => (
                        <tr>
                          <td>{service}</td>
                          {plansInfo.plans.map(plan => (
                            <td className={classes.center}>
                              {
                                plan.forbiddenRequests.component.includes(service) ?
                                  <Danger><Close /></Danger> :
                                  (plan.name === 'Tourist') ?
                                    <Warning><ReportProblemOutlinedIcon /></Warning> : <Success><Check /></Success>
                              }
                            </td>
                          ))
                          }
                        </tr>
                      ))

                    }

                  </tbody>
                  <br />
                  <tbody>
                    <tr>
                      <td> <strong>Price</strong></td>
                      {plansInfo.plans.map(plan => (
                        <td className={classes.center}><strong>{plan.pricePerMonth.startsWith('$0') ? 'Free' : `Just ${plan.pricePerMonth}`}</strong></td>))}
                    </tr>
                    <tr>
                      <td />
                      {plansInfo.plans.map(plan => (
                        <td className={classes.center}>
                          {
                            plan.name === user.plan ?
                              <Button round disabled>
                                Current Version
                      </Button>
                              :
                              <Button
                                round
                                color="warning"
                                // href="mailto:info.wo@frasal.uy"
                                onClick={() => handleOpen(plan.name)}
                              >
                                {`Change to ${plan.name.toUpperCase()}`}
                              </Button>
                          }
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </div>
            </CardBody>)
          }
        </Card>
      </GridItem>
      <SimpleDialog handleClose={() => setLoading(false)} content={<div style={{ width: '128px', padding: '20px', display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>} open={loading} />
      <SimpleDialog
        content={
          <div style={{ width: '500px', padding: '20px' }}>
            <CheckoutForm user={user} plan={plan} setLoading={setLoading} handleCloseModal={() => handleClose()} />
          </div>}
        open={open}
        handleClose={handleClose} >
      </SimpleDialog>
      <AlertDialogSlide content="Do you want to cancel your subscription?" open={openUnsubscribe} handleSubmit={handleSubmit} ></AlertDialogSlide>
    </GridContainer>
  );
}

const mapStateToProps = (state) => ({
  user: state.authentication.user
})

export default connect(mapStateToProps)(UpgradeToPro);