import React from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { subscriptionService } from 'services/subscription.service';
import CardSection from './CardSection';
import { userActions, alertActions } from 'redux/actions';
import { useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { planConstants } from 'constants/plan.constants';

export default function CheckoutForm({ user, plan, handleCloseModal, setLoading }) {
    const stripe = useStripe();
    const elements = useElements();

    const dispatch = useDispatch();

    const handleSCA = (response) => {
        if (response.paymentIntentStatus === 'requires_action') {
            stripe.confirmCardPayment(response.paymentIntentClientSecret, {
                payment_method: response.paymentMethodId,
            }).then((result) => {
                if (result.error) {
                    dispatch(alertActions.error(result.error.message));
                }
                else if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
                    subscriptionService.resumeSubscription(user.username, plan, response.paymentMethodId, response.incompleteSubscription.customer, response.incompleteSubscription.id)
                        .then(r => {
                            if (r.successful) {
                                dispatch(userActions.setPlan(plan))
                                dispatch(alertActions.success(`Plan successfully changed to ${plan}`));
                            } else {
                                if (r.data.paymentIntentStatus === 'requires_action') {
                                    dispatch(alertActions.error(`You card requires actions. Please try again.`));
                                }
                                else if (r.data.paymentIntentStatus === 'requires_payment_method') {
                                    dispatch(alertActions.error(`You card was declined. Please use another card.`));
                                } else {
                                    dispatch(alertActions.error(`An error ocurred.`));
                                }
                            }
                        }).catch(error => {
                            dispatch(alertActions.error(`An error ocurred.`));
                        })

                } else {
                    dispatch(alertActions.error(`An error ocurred.`));
                }
            });
        } else if (response.paymentIntentStatus === 'requires_payment_method') {
            dispatch(alertActions.error(`You card was declined. Please use another card.`));
        }
        else {
            dispatch(alertActions.error(`An error ocurred.`));
        }
    }

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            setLoading(false);
            return;
        }
        setLoading(true);

        // if (user.plan !== planConstants.Tourist) {
        //     subscriptionService.changeSubscription(user.username, plan)
        //         .then((r) => {
        //             console.log(r);
        //             if (r.successful) {
        //                 dispatch(userActions.setPlan(plan))
        //                 dispatch(alertActions.success(`Plan successfully changed to ${plan}`));
        //             } else {
        //                 dispatch(alertActions.error(`An error ocurred.`));
        //             }
        //             handleCloseModal()
        //             setLoading(false)
        //         })
        //         .catch((e) => {
        //             console.dir(e);
        //             dispatch(alertActions.error(`An error ocurred.`));
        //             setLoading(false)
        //         }
        //         );

        // } else {
        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);

        // If a previous payment was attempted, get the latest invoice
        // const latestInvoicePaymentIntentStatus = localStorage.getItem(
        //     'latestInvoicePaymentIntentStatus'
        // );

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        }).catch(() => setLoading(false));

        if (error) {
            console.log('[createPaymentMethod error]', error);
            setLoading(false);
        } else {
            console.log('[PaymentMethod]', paymentMethod);
            const paymentMethodId = paymentMethod.id;
            // if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
            //     // Update the payment method and retry invoice payment
            //     const invoiceId = localStorage.getItem('latestInvoiceId');
            //     retryInvoiceWithNewPaymentMethod({
            //         customerId,
            //         paymentMethodId,
            //         invoiceId,
            //         priceId,
            //     });
            // } else {
            // Create the subscription
            if (user.plan === planConstants.Tourist) {
                subscriptionService.createSubscription(user.username, plan, paymentMethodId).then((d) => {
                    console.log(d)
                    if (!d.successful) {
                        handleSCA(d.data);
                    } else {
                        dispatch(userActions.setPlan(plan))
                        dispatch(
                            alertActions.success(
                                `Plan successfully changed to ${plan}`,
                            ),
                        );
                    }
                })
                    .catch((e) => {
                        console.error(e);
                        setLoading(false);
                        handleCloseModal();
                    })
                    .then(() => setLoading(false))
                    .then(() => handleCloseModal());
            }
            // }
        }
        // }
    };

    const handleSubmitChange = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        setLoading(true);

        subscriptionService.changeSubscription(user.username, plan)
            .then((r) => {
                if (r.successful) {
                    dispatch(userActions.setPlan(plan))
                    dispatch(alertActions.success(`Plan successfully changed to ${plan}`));
                } else {
                    dispatch(alertActions.error(`An error ocurred.`));
                }

            })
            .catch((e) => {
                dispatch(alertActions.error(`An error ocurred.`));
            }
            ).finally(() => {
                handleCloseModal()
                setLoading(false)
            });
    };

    return user.plan === planConstants.Tourist ? (
        <div>
            <form onSubmit={handleSubmit}>
                <CardSection />
                <Button variant='primary' type='submit' disabled={!stripe} >Accept</Button>
            </form>
            <Button variant='primary' disabled={!stripe} onClick={handleCloseModal}>Cancel</Button>
        </div>)
        : (<div>
            <form onSubmit={handleSubmitChange}>
                <div style={{ width: '100%' }}>{`Are you sure do yo want to cahnge to ${plan}?`}</div>
                <Button variant='primary' type='submit'>Accept</Button>
            </form>

            <Button variant='primary' disabled={!stripe} onClick={handleCloseModal}>Cancel</Button>
        </div>
        );
}
