import { rotateArray } from 'helpers';
// import { Chartist } from 'chartist';
var Chartist = require('chartist');

// ##############################
// // // variables used to create animation on charts
// #############################
var delays = 80,
  durations = 500;

export function buildChart(labels, values, endsWith = -1) {
  const rotatedLabels = rotateArray(labels, labels.length - endsWith - 1);
  const rotatedValues = rotateArray(values, labels.length - endsWith - 1);
  const maxValue = Math.max(...values);
  const digits = Math.ceil(Math.log10(maxValue || 1));
  return {
    data: {
      labels: rotatedLabels,
      series: [rotatedValues],
    },
    options: {
      lineSmooth: Chartist.Interpolation.cardinal({
        tension: 0,
      }),
      low: 0,
      high: (maxValue + maxValue / 10) || 1, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
      chartPadding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: digits * (digits / 2),
      },
    },
    responsiveOptions: [
      [
        'screen and (max-width: 640px)',
        {
          seriesBarDistance: 5,
          axisX: {
            labelInterpolationFnc: function (value) {
              return value[0];
            },
          },
        },
      ],
    ],
    // for animation
    animation: {
      draw: function (data) {
        if (data.type === 'line' || data.type === 'area') {
          data.element.animate({
            d: {
              begin: 600,
              dur: 700,
              from: data.path.clone().scale(1, 0).translate(0, data.chartRect.height()).stringify(),
              to: data.path.clone().stringify(),
              easing: Chartist.Svg.Easing.easeOutQuint,
            },
          });
        } else if (data.type === 'point') {
          data.element.animate({
            opacity: {
              begin: (data.index + 1) * delays,
              dur: durations,
              from: 0,
              to: 1,
              easing: 'ease',
            },
          });
        }
      },
    },
  };
}
