// import config from 'config';
import axios from 'axios';
import environment from 'environment';

export const subscriptionService = {
    createSubscription,
    changeSubscription,
    cancelSubscription,
    retryInvoice,
    resumeSubscription
};

async function createSubscription(username, plan, paymentMethodId) {
    return axios
        .post(environment.api.planUrl + `/subscribe`, {
            username, plan, paymentMethodId
        })
        .then((response) => response.data)
        .catch((error) => Promise.reject(error.response));
}

async function resumeSubscription(username, plan, paymentMethodId, customerID, subscriptionID) {
    return axios
        .post(environment.api.planUrl + `/subscribe/resume`, {
            username, plan, paymentMethodId, customerID, subscriptionID
        })
        .then((response) => response.data)
        .catch((error) => Promise.reject(error.response));
}

async function retryInvoice(username, paymentMethodId, invoiceId) {
    return axios
        .put(environment.api.planUrl + `/retry`, {
            username, paymentMethodId, invoiceId
        })
        .then((response) => response.data)
        .catch((error) => Promise.reject(error.response));
}

async function changeSubscription(username, plan) {
    return axios
        .put(environment.api.planUrl + `/subscribe`, {
            username, plan
        })
        .then((response) => response.data)
        .catch((error) => Promise.reject(error.response));
}

async function cancelSubscription(username) {
    return axios
        .delete(environment.api.planUrl + `/unsubscribe`, {
            data: {
                username
            }
        })
        .then((response) => response.data)
        .catch((error) => Promise.reject(error.response));
}


//   handleInvoicePayment(response: IInvoice | IPaymentRequireAction) {
// function handleInvoicePayment(response) {
//     const redirectOnSucess = true;
//     if (response.successful) {
//         // OK
//         this.snackService.success('Ha realizado el pago correctamente.');
//     } else {
//         handleSCA(response, redirectOnSucess);
//     }
// }

// function handleSCA(response: IPaymentRequireAction, redirectOnSucess: boolean) {
