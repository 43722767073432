// import config from 'config';
import axios from 'axios';
import environment from 'environment';

export const requestService = {
  getLastWeekRequests,
  getHourlyRequests,
  getLastYearRequests,
  getRequests,
  getPlans,
  getPlanInfo
};

async function getPlans() {
  return axios
    .get(environment.api.planUrl + `/`)
    .then((response) => response.data)
    .catch((error) => false);
}

async function getPlanInfo(username) {
  console.log('Here')
  return axios
    .get(environment.api.planUrl + `/subscription`, { params: { username } })
    .then((response) => response.data)
    .catch((error) => false);
}

async function getRequests(apiKey) {
  return axios
    .get(environment.api.subscriberUrl + `/requests/remaining/${apiKey}`)
    .then((response) => response.data)
    .catch((error) => false);
}

async function getLastWeekRequests(apiKey) {
  try {
    const axiosResponse = await axios.get(`${environment.api.subscriberUrl}/requests/daily/${apiKey}`);
    const { requests, lastRequest } = axiosResponse.data;

    if (requests) {
      const week = new Array(7);
      for (let i = 0; i < week.length; i++) {
        week[i] = 0;
      }
      Object.entries(requests).forEach((e) => {
        week[e[0]] = e[1];
      });

      const weekDays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

      return {
        labels: Object.keys(week).map((i) => weekDays[i]),
        values: week,
        lastRequest,
      };
    }
  } catch (e) {
    return false;
  }
}

async function getHourlyRequests(apiKey) {
  try {
    const axiosResponse = await axios.get(`${environment.api.subscriberUrl}/requests/hourly/${apiKey}`);
    const { requests, lastRequest } = axiosResponse.data;

    if (requests) {
      const hoursPerInterval = 3;
      const numberOfIntervals = 24 / hoursPerInterval;

      const hours = new Array(numberOfIntervals);
      for (let i = 0; i < hours.length; i++) {
        hours[i] = 0;
      }
      Object.entries(requests).forEach((e) => {
        hours[~~(e[0] / hoursPerInterval)] += e[1] / 30;
      });

      return {
        labels: hours.map((k, i) => `${i * hoursPerInterval}-${(i + 1) * hoursPerInterval}`),
        values: hours,
        lastRequest,
      };
    }
  } catch (e) {
    return false;
  }
}

async function getLastYearRequests(apiKey) {
  try {
    const axiosResponse = await axios.get(`${environment.api.subscriberUrl}/requests/monthly/${apiKey}`);
    const { requests, lastRequest } = axiosResponse.data;

    if (requests) {
      const months = new Array(12);
      for (let i = 0; i < months.length; i++) {
        months[i] = 0;
      }
      Object.entries(requests).forEach((e) => {
        months[e[0]] = e[1];
      });

      const monthsNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];

      return {
        labels: Object.keys(months).map((i) => monthsNames[i]),
        values: months,
        lastRequest,
      };
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
}
