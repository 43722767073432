import withRoot from 'modules/withRoot';
// --- Post bootstrap -----
import React from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import Typography from 'components/Typography';
import AppForm from 'components/AppForm';
import { email, required } from 'modules/form/validation';
import RFTextField from 'modules/form/RFTextField';
import FormButton from 'modules/form/FormButton';
import FormFeedback from 'modules/form/FormFeedback';
import { userService } from '../services/index';
import { alertActions } from '../redux/actions/index';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(6),
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  feedback: {
    marginTop: theme.spacing(2),
  },
}));

function ForgotPassword() {
  const classes = useStyles();
  const [sent, setSent] = React.useState(false);
  const dispatch = useDispatch();
  const { resetEmail } = useParams();

  const validate = (values) => {
    const errors = required(['email'], values);

    if (!errors.email) {
      const emailError = email(values.email);
      if (emailError) {
        errors.email = email(values.email);
      }
    }

    return errors;
  };

  const handleSubmit = async (values) => {
    const { email } = values;
    console.log(email);
    setSent(true);
    try {
      await userService.passwordForgot(email);
      dispatch(alertActions.success('Verify your email to change your password!'));
    } catch (e) {
      dispatch(alertActions.error(e));
    }
    setSent(false);
  };

  return (
    <React.Fragment>
      <AppForm>
        <React.Fragment>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Forgot your password?
          </Typography>
          <Typography variant="body2" align="center">
            {"Enter your email address below and we'll " +
              'send you a link to reset your password.'}
          </Typography>
        </React.Fragment>
        <Form onSubmit={handleSubmit} subscription={{ submitting: true }} validate={validate}>
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} className={classes.form} noValidate>
              <Field
                autoFocus
                autoComplete="email"
                component={RFTextField}
                disabled={submitting || sent}
                fullWidth
                label="Email"
                margin="normal"
                name="email"
                required
                size="large"
                initialValue={resetEmail}
              />
              <FormSpy subscription={{ submitError: true }}>
                {({ submitError }) =>
                  submitError ? (
                    <FormFeedback className={classes.feedback} error>
                      {submitError}
                    </FormFeedback>
                  ) : null
                }
              </FormSpy>
              <FormButton
                className={classes.button}
                disabled={submitting || sent}
                size="large"
                color="secondary"
                fullWidth
              >
                {submitting || sent ? 'In progress…' : 'Send reset link'}
              </FormButton>
            </form>
          )}
        </Form>
      </AppForm>
    </React.Fragment>
  );
}

export default withRoot(ForgotPassword);
