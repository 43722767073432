// import { Route, Switch } from 'react-router-dom';

import Error from 'views/Error';
import HomeLayout from 'layouts/Home';
import AdminLayout from 'layouts/Admin';
import LoginLayout from 'layouts/Login';

const baseRoute = '/';

const routes = [
  { url: '/admin', component: AdminLayout },
  { url: '/home', component: HomeLayout },
  { url: '/', component: LoginLayout },
  { url: '*', component: Error }, //* al final para que se envíe solo en no-route-matching-case
];

export { baseRoute, routes };
