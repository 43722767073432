import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from 'components/Typography';

import productCurvyLines from "assets/img/productCurvyLines.png";
import productValues1 from "assets/img/productValues1.svg";
import productValues2 from "assets/img/productValues2.svg";
import productValues3 from "assets/img/productValues3.svg";
import productValues4 from "assets/img/productValues4.svg";
import productValues5 from "assets/img/productValues5.svg";
import productValues6 from "assets/img/productValues6.svg";

const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(30),
    display: 'flex',
    position: 'relative',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 55,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
  },
});

function ProductValues(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <img
          src={productCurvyLines}
          className={classes.curvyLines}
          alt="curvy lines"
        />
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src={productValues1}
                alt="graph"
              />
              <Typography variant="h6" className={classes.title}>
                Maps
              </Typography>
              <Typography variant="h5">
                {'Show our maps with all the information and options we have for you'}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src={productValues2}
                alt="clock"
              />
              <Typography variant="h6" className={classes.title}>
                APIs
              </Typography>
              <Typography variant="h5">
                {'Also, you can use our powerful location functionalities directly from the core'}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src={productValues3}
                alt="clock"
              />
              <Typography variant="h6" className={classes.title}>
                Toolkit
              </Typography>
              <Typography variant="h5">
                {'Or just use our options to build you need and display it as you want'}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src={productValues4}
                alt="suitcase"
              />
              <Typography variant="h6" className={classes.title}>
                All Places
              </Typography>
              <Typography variant="h5">
              {'Information built and processed to be just you need.'}
              </Typography>
              
              <Typography variant="h5">
                {'Get millions of POIs from our API with valuable data'}
              </Typography>
            </div>
          </Grid>
          
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src={productValues5}
                alt="clock"
              />
              <Typography variant="h6" className={classes.title}>
                Searches
              </Typography>
              <Typography variant="h5">
                {'Find points or directions anywhere'}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src={productValues6}
                alt="clock"
              />
              <Typography variant="h6" className={classes.title}>
                Routes
              </Typography>
              <Typography variant="h5">
                {'Calculate the route between two points just using their coordinates'}
              </Typography>
            </div>
          </Grid>

        </Grid>
      </Container>
    </section>
  );
}

ProductValues.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductValues);
