import React, { useState, useEffect } from 'react';
import { alertActions } from 'redux/actions';
import { useHistory } from 'react-router-dom';
import Button from 'components/Button';
import { useDispatch } from 'react-redux';
import Typography from '../components/Typography';
import { withStyles } from '@material-ui/core/styles';
import AppForm from 'components/AppForm';
import CircularProgress from '@material-ui/core/CircularProgress';
import { userService } from 'services';

const styles = (theme) => ({
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  fail: {
    textAlign: 'center',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    marginTop: theme.spacing(3),
  },
});
function VerifyEmail(props) {
  const EmailStatus = {
    Verifying: 'Verifying',
    Failed: 'Failed',
    Expired: 'Expired',
    Resending: 'Resending',
  };

  const [emailStatus, setEmailStatus] = useState(EmailStatus.Verifying);
  const history = useHistory();
  const dispatch = useDispatch();
  const { classes } = props;
  const [user, setUser] = useState('');

  useEffect(() => {
    const guid = props.match.params.guid;

    // remove token from url to prevent http referer leakage
    history.replace(window.location.pathname);

    userService
      .activation(guid)
      .then((user) => {
        setUser(user.username);
        if (user.activated) {
          dispatch(alertActions.success('Verification successful, you can now login'));
          history.push('/sign-in');
        } else {
          setEmailStatus(EmailStatus.Expired);
        }
      })
      .catch((e) => {
        setEmailStatus(EmailStatus.Failed);
      });
  }, []);

  const resendOnClick = () => {
    setEmailStatus(EmailStatus.Resending);
    userService
      .resendEmail(user)
      .then(() => {
        setEmailStatus(EmailStatus.Expired);
        dispatch(alertActions.success('Email sent successfully. Check your email again.'));
      })
      .catch((e) => {
        setEmailStatus(EmailStatus.Expired);
        dispatch(alertActions.error('Something went wrong. Try again later'));
      });
  };

  function getBody() {
    switch (emailStatus) {
      case EmailStatus.Verifying || EmailStatus.Resending:
        return (
          <div className={classes.center}>
            <CircularProgress />
          </div>
        );
      case EmailStatus.Failed:
        return (
          <Typography variant="h5" className={classes.fail}>
            Verification failed, try again later.
          </Typography>
        );
      case EmailStatus.Expired:
        return (
          <div>
            <Typography variant="h5" className={classes.fail}>
              Verification failed, activation code has expired.
            </Typography>
            <div className={classes.center}>
              <Button className={classes.button} onClick={resendOnClick} color="primary" variant="contained">
                Resend Email
              </Button>
            </div>
          </div>
        );
      default:
        return <h1>Email</h1>;
    }
  }

  return (
    <React.Fragment>
      <AppForm>
        <React.Fragment>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Verify Email
          </Typography>
          <Typography variant="body2" align="center"></Typography>
        </React.Fragment>
        <div className={classes.content}>{getBody()}</div>
      </AppForm>
    </React.Fragment>
  );
}

export default withStyles(styles)(VerifyEmail);
