import withRoot from 'modules/withRoot';
// --- Post bootstrap -----
import React, { useState } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import { required } from 'modules/form/validation';
import RFTextField from 'modules/form/RFTextField';
import FormButton from 'modules/form/FormButton';
import FormFeedback from 'modules/form/FormFeedback';
import Typography from 'components/Typography';
import AppAppBar from 'components/AppAppBar';
import AppForm from 'components/AppForm';
import { useDispatch } from 'react-redux';
import { alertActions } from 'redux/actions';
import { useHistory, useParams } from 'react-router-dom';
import { userService } from 'services';

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(6),
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  feedback: {
    marginTop: theme.spacing(2),
  },
}));

function ResetPassword(props) {
  const classes = useStyles();
  const history = useHistory();
  const [sent, setSent] = useState(false);
  const { resetPasswordCode } = useParams();

  const dispatch = useDispatch();

  // history.listen((mlocation, action) => {
  //   dispatch(alertActions.clear());
  // });

  const validate = (values) => {
    const errors = required(['password', 'confirmPassword'], values);

    return errors;
  };

  const handleSubmit = async (values) => {
    const { password, confirmPassword } = values;
    setSent(true);
    if (password !== confirmPassword) {
      dispatch(alertActions.error('Passwords don\'t match.'));
    } else {
      try {
        await userService.passwordReset(password, resetPasswordCode);
        dispatch(alertActions.success('Password changed successfully!'));
        history.push('/sign-in');
      } catch (e) {
        dispatch(alertActions.error(e));
      }
    }
    setSent(false);
  };

  return (
    <React.Fragment>
      <AppAppBar />
      <AppForm>
        <React.Fragment>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Reset Password
          </Typography>
        </React.Fragment>
        <Form
          onSubmit={handleSubmit}
          subscription={{ submitting: true }}
          validate={validate}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} className={classes.form} noValidate>
              <Field
                fullWidth
                size="large"
                component={RFTextField}
                disabled={(submitting || sent)}
                required
                name="password"
                autoComplete="current-password"
                label="Password"
                type="password"
                margin="normal"
              />
              <Field
                fullWidth
                size="large"
                component={RFTextField}
                disabled={(submitting || sent)}
                required
                name="confirmPassword"
                autoComplete="confirmCurrent-password"
                label="Confirm Password"
                type="password"
                margin="normal"
              />
              <FormSpy subscription={{ submitError: false }}>
                {({ submitError }) =>
                  submitError ? (
                    <FormFeedback className={classes.feedback} error>
                      {submitError}
                    </FormFeedback>
                  ) : null
                }
              </FormSpy>
              <FormButton
                className={classes.button}
                disabled={(submitting || sent)}
                size="large"
                color="secondary"
                fullWidth
              >
                {(submitting || sent) ? 'In progress…' : 'Reset password'}
              </FormButton>
            </form>
          )}
        />
      </AppForm>
    </React.Fragment>
  );
}

export default withRoot(ResetPassword);
