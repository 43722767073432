export default function (baseApi) {
  return {
    api: {
      subscriberUrl: `${baseApi}/subscribers`,
      planUrl: `${baseApi}/plans`
    },
    isProduction: true,
    isDevelopment: false,
  };
}
