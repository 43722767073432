import { userConstants } from '../../constants';
import { userService } from '../../services';
import { alertActions } from '.';

export const userActions = {
  login,
  logout,
  register,
  setPlan
};

function login(username, password, history) {
  return (dispatch) => {
    dispatch(request({ username }));

    userService.login(username, password).then(
      (user) => {
        dispatch(success(user));
        history.push('/admin');
      },
      (error) => {
        dispatch(failure(error.status, username));
        dispatch(alertActions.error(error.message));
      },
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error, username) {
    return { type: userConstants.LOGIN_FAILURE, activationFailure: error === 401, username };
  }
}

function logout() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}

function register(username, password, history) {
  return (dispatch) => {
    dispatch(request());
    userService.register(username, password).then(
      (d) => {
        dispatch(
          alertActions.success(
            'Registration successful, please check your email for verification instructions',
          ),
        );
        history.push('/sign-in');
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      },
    );
  };
  function request() {
    return { type: userConstants.REGISTER_REQUEST };
  }

  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function setPlan(plan) {
  return { type: userConstants.SET_PLAN, payload: { plan } };
}
