import { userConstants } from '../../constants';
import { stat } from 'fs';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user,
      };

    case userConstants.LOGIN_FAILURE:
      return {
        activationFailure: action.activationFailure,
        username: action.username
      };
    case userConstants.LOGOUT:
      return {};

    case userConstants.REGISTER_REQUEST:
      return {
        registeringIn: true,
      };
    case userConstants.REGISTER_SUCCESS:
      return {
        registeredIn: true,
      };
    case userConstants.REGISTER_FAILURE:
      return {};
    case userConstants.SET_PLAN:
      return {
        user: {
          ...state.user,
          plan: action.payload.plan
        }
      }
    default:
      return state;
  }
}
