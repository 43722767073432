import withRoot from 'modules/withRoot';
// --- Post bootstrap -----
import React, { useState } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import { email, required } from 'modules/form/validation';
import RFTextField from 'modules/form/RFTextField';
import FormButton from 'modules/form/FormButton';
import FormFeedback from 'modules/form/FormFeedback';
import Typography from 'components/Typography';
import AppAppBar from 'components/AppAppBar';
import AppForm from 'components/AppForm';
import { connect } from 'react-redux';
import { userActions, alertActions } from 'redux/actions';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { userService } from 'services';
import stepUpLogo from '../assets/img/step-up-logo.svg';

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(6),
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  feedback: {
    marginTop: theme.spacing(2),
  },
}));

function SignIn(props) {
  const classes = useStyles();
  const history = useHistory();
  const [sent, setSent] = useState(false);
  const { loggingIn, activationFailure, username, dispatch } = props;
  const [resetEmail, setResetEmail] = useState('');

  history.listen((mlocation, action) => {
    dispatch(alertActions.clear());
  });

  const validate = (values) => {
    const errors = required(['email', 'password'], values);

    if (!errors.email) {
      const emailError = email(values.email);
      // const emailError = email(values.email, values);
      if (emailError) {
        errors.email = email(values.email);
        // errors.email = email(values.email, values);
      }
    }
    return errors;
  };

  const resendEmail = (user) => {
    userService
      .resendEmail(user)
      .then(() => {
        dispatch(alertActions.success('Email sent successfully. Check your email again.'));
      })
      .catch((e) => {
        dispatch(alertActions.error('Something went wrong. Try again later'));
      });
  };

  const handleSubmit = (values) => {
    const { email, password } = values;

    setSent(true);
    if (email && password) {
      dispatch(userActions.login(email, password, history));
    }
    // setloggedIn(true);
  };

  const handleResendLink = () => {
    if (activationFailure) {
      resendEmail(username);
    }
  };

  return (
    <React.Fragment>
      <img src={stepUpLogo} alt="StepUp logo" />

      {/* <AppForm>
        <React.Fragment>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Sign In
          </Typography>
          <Typography variant="body2" align="center">
            {'Not a member yet? '}
            <Link href="/sign-up/" align="center" underline="always">
              Sign Up here
            </Link>
          </Typography>
        </React.Fragment>
        <Form
          onSubmit={handleSubmit}
          subscription={{ submitting: true }}
          validate={validate}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} className={classes.form} noValidate>
              <Field
                autoComplete="email"
                autoFocus
                component={RFTextField}
                disabled={(submitting || sent) && loggingIn}
                fullWidth
                label="Email"
                margin="normal"
                name="email"
                required
                size="large"
              />
              <Field
                fullWidth
                size="large"
                component={RFTextField}
                disabled={(submitting || sent) && loggingIn}
                required
                name="password"
                autoComplete="current-password"
                label="Password"
                type="password"
                margin="normal"
              />
              <FormSpy subscription={{ submitError: false }}>
                {({ submitError }) =>
                  submitError ? (
                    <FormFeedback className={classes.feedback} error>
                      {submitError}
                    </FormFeedback>
                  ) : null
                }
              </FormSpy>
              <FormButton
                className={classes.button}
                disabled={(submitting || sent) && loggingIn}
                size="large"
                color="secondary"
                fullWidth
              >
                {(submitting || sent) && loggingIn ? 'In progress…' : 'Sign In'}
              </FormButton>
              <Typography align="center">
                <RouterLink to={`/forgot-password/${resetEmail}`}>
                  <Link underline="always">
                    <span style={{ cursor: 'pointer' }}>Forgot password?</span>
                  </Link>
                </RouterLink>
              </Typography>
              {activationFailure ? (
                <Typography align="center">
                  <Link underline="always" type="submit" onClick={handleResendLink}>
                    <span style={{ cursor: 'pointer' }}>Resend email</span>
                  </Link>
                </Typography>
              ) : null}
              <FormSpy onChange={(state) => setResetEmail(state.values.email)}></FormSpy>
            </form>
          )}
        />
      </AppForm> */}
    </React.Fragment>
  );
}
function mapStateToProps(state) {
  const { loggingIn, activationFailure, username } = state.authentication;
  return {
    loggingIn,
    activationFailure,
    username,
  };
}
export default withRoot(connect(mapStateToProps)(SignIn));
