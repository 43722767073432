import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import AppBar from 'components/AppBar';
import Toolbar, { styles as toolbarStyles } from 'components/Toolbar';
import { userActions } from 'redux/actions';
import { connect } from 'react-redux';
import logo from 'assets/img/wo.png';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import IconButton from '@material-ui/core/IconButton';
import Person from '@material-ui/icons/Person';
import Menu from '@material-ui/icons/Menu';
import { useHistory } from 'react-router-dom';
import Typography from './Typography';

const styles = (theme) => ({
  title: {
    fontSize: 24,
  },
  placeholder: toolbarStyles(theme).root,
  toolbar: {
    justifyContent: 'space-between',
  },
  image: {
    width: '64px',
  },
  left: {
    flex: 0,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  leftLinkActive: {
    color: theme.palette.common.white,
  },
  right: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  rightLink: {
    fontSize: 16,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(3),
  },
  divider: {
    fontSize: 24,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(3),
  },
  linkSecondary: {
    color: theme.palette.secondary.main,
  },
});

function AppAppBar(props) {
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (window.innerWidth < 960) {
      setIsMobile(true);
    }
  }, [window.innerWidth])

  const handleLogOut = () => {
    const { dispatch } = props;
    dispatch(userActions.logout());
  };

  const handleRoute = (route) => {
    history.push(route);
  };

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const { classes, user } = props;
  let signUp = (
    <Link
      variant="h6"
      underline="none"
      className={clsx(classes.rightLink, classes.linkSecondary)}
      href="/sign-up"
    >
      {'Sign Up'}
    </Link>
  );

  let signIn = (
    <Link color="inherit" variant="h6" underline="none" className={classes.rightLink} href="/sign-in">
      {'Sign In'}
    </Link>
  );

  let signOut = (
    // <Link
    //   color="inherit"
    //   variant="h6"
    //   underline="none"
    //   className={classes.rightLink}
    //   href="#"
    //   onClick={handleLogOut}
    // >
    //   {'Sign Out'}
    // </Link>
    <div>
      <IconButton
        className={classes.rightIcon}
        color="inherit"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Person />
      </IconButton>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {isMobile ? <MenuItem onClick={() => handleRoute('/pricing')}>Pricing</MenuItem> : undefined}
                  {isMobile ? <MenuItem onClick={() => handleRoute('/documentation')}>Documentation</MenuItem> : undefined}
                  <MenuItem onClick={() => handleRoute('/admin')}>My account</MenuItem>
                  <MenuItem onClick={handleLogOut}>Logout</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
  if (user) {
    signIn = null;
    signUp = null;
  } else {
    signOut = null;
  }

  const rightMenu = (isMobile ?
    <div>
      <IconButton
        className={classes.rightIcon}
        color="inherit"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Menu />
      </IconButton>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <MenuItem onClick={() => handleRoute('/pricing')}>Pricing</MenuItem>
                  <MenuItem onClick={() => handleRoute('/documentation')}>Documentation</MenuItem>
                  {user ? <MenuItem onClick={() => handleRoute('/admin')}>My account</MenuItem> : <MenuItem onClick={() => handleRoute('/sign-in')}>SignIn</MenuItem>}
                  {user ? <MenuItem onClick={handleLogOut}>Logout</MenuItem> : <MenuItem onClick={() => handleRoute('/sign-up')}>SignUp</MenuItem>}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
    :
    <div className={classes.right}>
      <Link underline="none" color="inherit" variant="h6" className={classes.rightLink}
        href="/pricing">
        Pricing
            </Link>

      <Link underline="none" color="inherit" variant="h6" className={classes.rightLink}
        href="/documentation">
        Documentation
            </Link>

      <Typography color="inherit" variant="h1" underline="none" className={classes.divider} > | </Typography>

      {signIn}
      {signUp}
      {signOut}
    </div>

  )

  return (
    <div>
      <AppBar position="fixed">
        <Toolbar className={classes.toolbar}>
          <Link variant="h6" underline="none" color="inherit" className={classes.title} href="/">
            <div className={classes.left} >

              <div>
                <img src={logo} className={classes.image} ></img>
              </div>
              {isMobile ? undefined : <div>
                <p>Locations</p>
              </div>}
            </div>

          </Link>

          {rightMenu}
        </Toolbar>
      </AppBar>
      <div className={classes.placeholder} />
    </div>
  );
}

AppAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};
function mapStateToProps(state) {
  const {
    authentication: { user },
  } = state;
  return {
    user,
  };
}
export default withStyles(styles)(connect(mapStateToProps)(AppAppBar));
