import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
import Close from '@material-ui/icons/Close';
import Check from '@material-ui/icons/Check';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

// core components
import GridItem from 'components/Admin/Grid/GridItem';
import GridContainer from 'components/Admin/Grid/GridContainer';
import Danger from 'components/Admin/Typography/Danger';
import Warning from 'components/Admin/Typography/Warning';
import Success from 'components/Admin/Typography/Success';
import Card from 'components/Admin/Card/Card';
import CardHeader from 'components/Admin/Card/CardHeader';
import CardBody from 'components/Admin/Card/CardBody';
import { requestService } from 'services';
import CircularProgress from '@material-ui/core/CircularProgress';
import { capitalize } from 'helpers/string';

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  tableUpgradeWrapper: {
    display: 'block',
    width: '100%',
    overflowX: 'auto',
    WebkitOverflowScrolling: 'touch',
    MsOverflowStyle: '-ms-autohiding-scrollbar',
  },
  table: {
    width: '100%',
    maxWidth: '100%',
    marginBottom: '1rem',
    backgroundColor: 'transparent',
    borderCollapse: 'collapse',
    display: 'table',
    borderSpacing: '2px',
    borderColor: 'grey',
    '& thdead tr th': {
      fontSize: '1.063rem',
      padding: '12px 8px',
      verticalAlign: 'middle',
      fontWeight: '300',
      borderTopWidth: '0',
      borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
      textAlign: 'inherit',
    },
    '& tbody tr td': {
      padding: '12px 8px',
      verticalAlign: 'middle',
      borderTop: '1px solid rgba(0, 0, 0, 0.06)',
    },
    '& td, & th': {
      display: 'table-cell',
    },
  },
  center: {
    textAlign: 'center',
  },
};

const useStyles = makeStyles(styles);

const Pricing = ({ user }) => {
  const classes = useStyles();
  const [plansInfo, setPlansInfo] = useState({ loading: true, error: false });

  useEffect(() => {
    (async () => {
      setPlansInfo({ loading: true, error: false });
      let plans = await requestService.getPlans();
      console.log(plans);
      setPlansInfo({ ...plans, loading: false, error: !plans });
    })()
  }, [])

  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={12} md={8}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>Wo Locations Plans</h4>

          </CardHeader>
          {plansInfo.error ? <h3 className={classes.center}> Something went wrong, please try again! </h3> : (plansInfo.loading ? <CircularProgress /> :
            <CardBody>
              <div className={classes.tableUpgradeWrapper}>
                <table className={classes.table}>
                  <thead>
                    <tr>
                      <th />
                      {plansInfo.plans.map(plan => (
                        <th className={classes.center}><h5><strong>{capitalize(plan.name)}</strong></h5></th>))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Requests per day</td>
                      {plansInfo.plans.map(plan => (
                        <td className={classes.center}>{plan.requestPerDay < 0 ? 'Unlimited' : plan.requestPerDay}</td>))}
                    </tr>
                    <tr>
                      <td>Request frequency</td>
                      {plansInfo.plans.map(plan => (
                        <td className={classes.center}>{plan.requestPerSecond < 0 ? 'Unlimited' : `${plan.requestPerSecond}/s`}</td>))}
                    </tr>
                  </tbody>

                  <thead>
                    <tr>
                      <td><h5>Api Services</h5></td>
                      <th />  <th /><th />
                    </tr>
                  </thead>

                  <tbody>

                    {
                      plansInfo.services.api.map(service => (
                        <tr>
                          <td>{service}</td>
                          {plansInfo.plans.map(plan => (
                            <td className={classes.center}>{plan.forbiddenRequests.api.includes(service) ?
                              <Danger><Close /></Danger> : <Success><Check /></Success>}</td>))}
                        </tr>
                      ))
                    }

                  </tbody>

                  <thead>
                    <tr>
                      <td><h5>Component Services</h5></td>
                      <th /><th /><th />
                    </tr>
                  </thead>

                  <tbody>

                    {
                      plansInfo.services.component.map(service => (
                        <tr>
                          <td>{service}</td>
                          {plansInfo.plans.map(plan => (
                            <td className={classes.center}>
                              {
                                plan.forbiddenRequests.component.includes(service) ?
                                  <Danger><Close /></Danger> :
                                  (plan.name === 'Tourist') ?
                                    <Warning><ReportProblemOutlinedIcon /></Warning> : <Success><Check /></Success>
                              }
                            </td>
                          ))
                          }
                        </tr>
                      ))

                    }
                    <tr>
                      <td> <strong>Price</strong></td>
                      {plansInfo.plans.map(plan => (
                        <td className={classes.center}><strong>{plan.pricePerMonth.startsWith('$0') ? 'Free' : `Just ${plan.pricePerMonth}`}</strong></td>))}
                    </tr>
                  </tbody>
                  <br />
                </table>
              </div>
            </CardBody>)
          }
        </Card>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => ({
  user: state.authentication.user
})

export default connect(mapStateToProps)(Pricing);