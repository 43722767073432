import React from 'react';
import Dialog from '@material-ui/core/Dialog';


export function SimpleDialog(props) {
    return (
        <Dialog aria-labelledby="simple-dialog-title" open={props.open} onBackdropClick={props.handleClose}>
            {props.content}
        </Dialog>
    );
}