import withRoot from 'modules/withRoot';
// --- Post bootstrap -----
import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { routes, baseRoute } from 'routes/routes';
import { connect } from 'react-redux';
import { userActions, alertActions } from 'redux/actions';
import Snackbar from 'components/Snackbar';
import PrivateRoute from 'components/PrivateRoute';
import IdleTimer from 'react-idle-timer';

import "swagger-ui-react/swagger-ui.css"

const Body = () => (
  <Switch>
    {routes.map((r) =>
      r.url === '/admin' ? (
        <PrivateRoute key={r.url} path={r.url} component={r.component} />
      ) : (
          <Route
            // exact={r.url !== '*'} //* la unica que no es exact es * porque es cualquier ruta => Error Page
            // exact={r.exact} //* la unica que no es exact es * porque es cualquier ruta => Error Page
            key={r.url}
            path={r.url}
            component={r.component}
          />
        ),
    )}
  </Switch>
);

function Index(props) {
  const { dispatch, alert } = props;
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    dispatch(alertActions.clear());
  };

  let idleTimer = null;

  const onIdle = (e) => {
    console.log('user is idle', e);
    if (idleTimer.getRemainingTime() === 0) {
      dispatch(userActions.logout());
    }
  };

  return (
    <React.Fragment>
      <div>
        <IdleTimer
          ref={(ref) => {
            idleTimer = ref;
          }}
          element={document}
          onIdle={onIdle}
          debounce={250}
          timeout={1000 * 60 * 180}
        />
        {alert.message && <Snackbar open={alert.message} message={alert.message} onClose={handleClose} />}
        <BrowserRouter basename={baseRoute}>
          <Body />
        </BrowserRouter>
      </div>
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  const { alert } = state;
  return {
    alert,
  };
}

export default withRoot(connect(mapStateToProps)(Index));
