import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem('user') || props.user ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/sign-in', state: { from: props.location } }} />
      )
    }
  />
);
function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}
export default connect(mapStateToProps)(PrivateRoute);
