import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import GridItem from 'components/Admin/Grid/GridItem';
import GridContainer from 'components/Admin/Grid/GridContainer';
import Card from 'components/Admin/Card/Card';
import CardHeader from 'components/Admin/Card/CardHeader';
import CardBody from 'components/Admin/Card/CardBody';
import SwaggerUI from "swagger-ui-react"

import environment from 'environment';

const styles = {
    cardCategoryWhite: {
        color: 'rgba(255,255,255,.62)',
        margin: '0',
        fontSize: '14px',
        marginTop: '0',
        marginBottom: '0',
    },
    cardTitleWhite: {
        color: '#FFFFFF',
        marginTop: '0px',
        minHeight: 'auto',
        fontWeight: '300',
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: '3px',
        textDecoration: 'none',
    },
};

const useStyles = makeStyles(styles);

export default function Documentation() {
    const classes = useStyles();
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>API-Docs</h4>
                        </CardHeader>
                        <CardBody>
                            {/* <a href='localhost:3000/api-docs/swagger'> Test Link </a> */}
                            <SwaggerUI url={environment.swaggerUrl} />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}
